import { ILanguage } from "./ILanguage";

export const languageEN: ILanguage = {
  COMMON: {
    ProjectName: "Bonus",
    Continue: "Continue",
    Yes: "Yes",
    No: "No",
    Cancel: "Cancel",
    Create: "Create",
    Add: "Add",
    Activate: "Activate",
    Import: "Import",
    Change: "Change",
    Suspend: "Suspend",
    Save: "Save",
    Edit: "Edit",
    Delete: "Delete",
    Remove: "Remove",
    Deactivate: "Deactivate",
    Logout: "Logout",
    Optional: "Optional",
    Registar: "Create",
    PrepositionForDateTime: " on ",
    CreatedBy: "Registed by",
    CreatedOn: "Registed on",
    All: "All",
    Clear: "Clear",
    Deliver: "Deliver",
    OtherActions: "Other actions",
    CloneAction: "Clone Sample",
    SelectOneOptionPlaceholder: "Select one option",
    Export: "Export",
    Weight: "Weight",
    Confirm: "Confirm",
    Name: "Name",
    Upload: "Upload",
    Status: "Status",
    SaveAll: "Save All",
    ImportCollaborators: "Import Collaborators",
    DeleteCAPSLOCK: "Remove",
    Saved: "Information changed.",

    POPUPS: {
      Download: "Download",
      ImportOptions: "Import Options",
      ExcelTemplate: "Excel Template",
      PdfTemplate: "PDF Template",
      ExcelTemplateText: "Click the button below to download an Excel template file. This template contains the correct structure for data import.",
      PdfTemplateText: "Click the button below to download an PDF template file. This is an example of performance appraisal guidance for team managers.",
      Import: "Import",
      ImportText: "Click the button below to select an Excel file and start the import process. Make sure you select the correct file.",
      ImportEvaluationAttachs: "Click the button below to import a file. This file will be displayed for all users with permission to access Evaluation's section.",
      SelectDate: "Select a date"
    },

    ERRORS: {
      ErrorSavingValues: "There was a problem saving the data. Please verify unsaved changes."
    },

    FORM: {
      Details: "Details",
      Search: "Search",
      SearchPlaceholder: "Search",
      InitialDate: "Initial Date",
      InitialDatePlaceholder: "Initial Date",
      InitialCreationDatePlaceholder: "Initial Creation Date",
      FinalDate: "Final Date",
      FinalDatePlaceholder: "Final Date",
      FinalCreationDatePlaceholder: "Final Creation Date",
      RequestDatePlaceholder: "Select a date",
      DefaultPlaceholder: "Type Here",
      SelectDefaultPlaceholder: "Type to search",
      SelectStatusPlaceholder: "Select status",
      Filters: "Filters",
      CleanFilters: "Reset Filters",
      ClearSelection: "Clear selection",
      Sunday: "Su",
      Monday: "Mo",
      Tuesday: "Tu",
      Wednesday: "We",
      Thursday: "Th",
      Friday: "Fr",
      Saturday: "Sa",

      VALIDATIONS: {
        CannotBeEmpty: "This field cannot be empty.",
        RequiredField: "This field is required.",
        GuidValidator: "Invalid GUID format.",
        SelectOneOption: "Select an option",
        BatchFormatError: "Must contain the following format: ####### or #######/# or #######/##",
        FillMandatoryFields: "Please fill the mandatory fields",
        InvalidLocationId: "Location ID is invalid.",
        InvalidRequestId: "Analysis Request ID is invalid.",
        PositiveNumber: "Insert an integer number",
        PositiveNumberOrDecimal: "Insert an integer number / decimal",
        ZeroOrDecimalNumber: "Insert an integer/decimal number",
        SelectDate: "Please select a date",

      }
    },

    ERRORPAGES: {
      SomethingWrong: "Something Went Wrong",
      BackToHome: "Back to Home",
      AccessDenied: "Access Denied",
      AccessDeniedText1: "You do not have permission to view this page!",
      AccessDeniedText2: "Please check your credentials and try again.",
      PageNotFound: "Page Not Found",
      PageNotFoundText: "We can't seem to find the page you are looking for.",
      SessionExpired: "Session Expired",
      SessionExpiredText: "Your session has expired due to your inactivity.",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Error!",
      ErrorText: "",
      Success: "Success!",
      SuccessText: "",
      Warning: "Warning",
      WarningText: "",
    },

    TABLE: {
      NoResults: "No results found",
      NoResultsText: "We couldn't find what you're looking for, please search again",
      RowsPerPage: "Rows per page",
      Of: "of",
    }
  },

  PERIODS: {
    Period: "Period",
    RegisteredPeriodMessage: "This evaluation period is in the definition phase by the administration, therefore, evaluations are not yet available.",
    EvaluationPeriods: "Evaluation Periods",
    EvaluationPeriodDetails: "Evaluation Period Details",
    CreatePeriod: "Create Period",
    EditEvaluationPeriod: "Edit Evaluation Period",
    ClonePeriod: "Clone Evaluation Period",
    CreateEvaluationPeriod: "Create Evaluation Period",
    SimilarPreviousPeriod: "Similar to previous period",
    Cancel: "Cancel",
    Activate: "Activate",
    Close: "Close",
    Matrix: "Matrix",
    Managers: "Managers",
    HoursAndAbsenceMap: "Hours And Absence Map",
    Salaries: "Salaries",
    EvaluationsAttachments: "Support Manuals",
    Reopen: "Reopen",
    SubTotalAchieved: "Subtotal Achieved",


    INFOANDFORM: {
      SumFactorsWarning: "Sum of the weight of Company and Employee Factors must be 100%",
      EvaluationPeriodData: "Evaluation Period Data",
      Name: "Name",
      NamePlaceholder: "Ex: 1st Quarter 2023",
      InitialDate: "Initial Date",
      InitialDatePlaceholder: "Select an Initial Date",
      FinalDate: "Final Date",
      FinalDatePlaceholder: "Select a Final Date",
      CompanyEvaluationFactors: "Company Evaluation Factors",
      EmployeeEvaluationFactors: "Employee Evaluation Factors",
      Factor: "Factor",
      FactorPlaceholder: "Ex: Attendance",
      Description: "Description",
      DescriptionPlaceholder: "Type a description of the factor",
      Weight: "Weight",
      WeightPlaceholder: "Value",
      Max: "Max",
      MaxPlaceholder: "Value",
      Min: "Min",
      MinPlaceholder: "Value",
      PresentValue: "Current Value",
      PresentValuePlaceholder: "Value",
      Evaluation: "Evaluation",
      EvaluationPlaceholder: "Value",
      AddFactor: "Add Factor",
      MonthlySalaryMultiplier: "Monthly Salary multiplier",
      PeriodDays: "N.º of Days in the Period",
      PeriodMonetaryCeilingPercentage: "Period Monetary Ceiling Percentage",
      PeriodDaysReduced: "N.º of Days",
      MonthlySalaryMultiplierReduced: "Salary multiplier",
      PeriodMonetaryCeilingPercentageReduced: "Monetary Ceiling (%)",
      PeriodMonetaryCeiling: "Monetary Ceiling",
      MinimumBonusPercentage: "Minimum percentage to reach Bonus",
      MinimumBonusPercentageV2: "Min. perc. to reach Bonus",
      SaveAllCompanyCriterias: "Save all company criterias",
      SaveAllEmployeeCriterias: "Save all employee criterias",
      
      InitialDayToEvaluate: "First day to Evaluate",
      FinalDayToEvaluate: "Last day to Evaluate",
      InitialDayToCalibrate: "First day to Calibrate",
      FinalDayToCalibrate: "Last day to Calibrate",

      DatesToNotificateManagersByEmail: "Dates to notify Managers via e-mail",

      StartEvaluations: "Start Evaluations",
      EndEvaluations: "End Evaluations",
      
      StartCalibrations: "Start Calibrations",
      EndCalibrations: "End Calibrations"

    },

    VALIDATIONS: {
      WeigthPercentage: "Type a number between 0-100",
    },

    CRITERIAS: {
      Name: "Name",
      NamePlaceholder: "Type Name",
      Description: "Description",
      DescriptionPlaceholder: "Type Description",
      WeightPercentage: "Weight (%)",
      WeightPercentagePlaceholder: "Type Weight (%)",
      TargetValue: "Target Value",
      TargetValuePlaceholder: "Type Target",
      TargetValueV2: "Maximum Value",
      TargetValueV2Placeholder: "Type Maximum value",
      StartValue: "Start Value",
      StartValuePlaceholder: "Type Start Value",
      StartValueV2: "Minimum Value",
      StartValueV2Placeholder: "Type Minimum Value",
      CurrentValue: "Present Value",
      CurrentValuePlaceholder: "Type Present value",
      Achievement: "Achievement",
      AchievementPlaceholder: "Type Achievement (%)",
      Score: "Score",
    },

    MESSAGES: {
      CreatePeriodSuccess: "New Evaluation Period Created",
      EditPeriodSuccess: "Evaluation Period Edited",
      PeriodCanceledSuccess: "Evaluation Period Canceled",
      PeriodAtivatedSuccess: "Evaluation Period in Progress",
      PeriodCompletedSuccess: "Evaluation Period Completed",
      PeriodReopenSuccess: "Evaluation Period Reopened",
      CreatedCompanyCriteriaSuccess: "Company Factor created",
      MultipleCompanyCriteriasUpdatedSuccess: "Company Criterias Updated",
      MultipleEmployeeCriteriasUpdatedSuccess: "Employee Criterias Updated",
      ChangedCompanyCriteriaSuccess: "Company Factor changed",
      DeleteCompanyCriteriaSucess: "Company Factor deleted",
      CreatedEmployeeCriteriaSuccess: "Employee Factor created",
      ChangedEmployeeCriteriaSuccess: "Employee Factor changed",
      DeleteEmployeeCriteriaSucess: "Employee Factor deleted",
    },


    STATUS: {
      Rejected: "Rejected",
      Confirmed: "Confirmed",
      OnGoing: "On Going",
      ReadyToEvaluate: "Ready To Evaluate",
      Calibrated: "Calibrated"
    },

    STATUS2: {
      Canceled: "Canceled",
      Completed: "Completed",
      InProgress: "In Progress",
      Registered: "Registered",
    },

    POPUPS: {
      Cancel: "Cancel",
      CancelEvaluationPeriodConfirmText: "Are you sure you want to cancel the evaluation period?",
      Close: "Close",
      CloseEvaluationPeriodConfirmText: "Are you sure you want to close the evaluation period?",
      Activate: "Activate",
      ActivateEvaluationPeriodConfirmText: "Are you sure you want to activate the evaluation period?",
      Reopen: "Reopen",
      ReopenEvaluationPeriodConfirmText: "Are you sure you want to reopen the evaluation period?",
    }
  },

  MANAGERS: {
    PeriodManagers: "Period Managers",
    Managers: "Managers",
    AddManager: "Add Manager",
    AddManagerSuccessMessage: "New Manager added",
    AddMultipleManagersSuccessMessage: "New Managers added",
    DeleteManagerConfirmText: "Are you sure you pretend to remove this Manager?",
    DeleteManagerSuccessMessage: "Manager Removed",
    Manager: "Manager",
    Availability: "Availability",

    INFOANDFORM: {
      OtherManagerSelectedTitle: "New Manager Selected",
      SuggestedDelegationTitle: "Delegation suggested by the system",
      SelectedManager: "Selected Manager",
      SearchOtherPerson: "If you intend to delegate to another person, use the search box below",
      SearchOtherManager: "Search another Manager",
      DelegateEvaluations: "Delegate Evaluations",
      DelegatedTo: "Delegated to",
      Delegations: "Delegations",
      Delegate: "Delegate",
      RemoveDelegation: "Remove Delegation",
      RemoveManager: "Remove Manager",
      NoSuperiorFoundMessage: "No direct superior to '{0}' was found to delegate",
      Superior: "Superior",
      NoSuggestionFound: "No suggestions were found to delegate"
    },

    POPUPS: {
      RemoveDelegationConfirmText: "Are you sure you want to remove this delegation?",
      RemoveDelegationSuccessMessage: "Delegation removed!",
      DelegateManagerSuccess: "Manager delegated!",
    }
  },


  HOURSANDABSENCE: {
    HoursAndAbsenceMap: "Hours And Absence Map",
    AbsenceMap: "Absences",
    ViewAbsenceRecords: "Click to check the absence record",

    AddMultipleHoursRecordsSuccessMessage: "New Hours Records Added",
    INFOANDFORM: {
      CollaboratorName: "Collaborator Name",
      AbsenteeismCode: "Absenteeism Code",
      AbsenceHours: "Absence Hours",
      AbsenceDays: "Absence Days",
      CodeDescription: "Absenteeism Description",
      Problematic: "Problematic"
    },

    POPUPS: {
      ImportHoursMapConfirmText: "Reimport 'Hours and Absence Map' will overwrite the existing data..",
    }
  },

  SALARIES: {
    Salaries: "Salaries",
    AddMultipleSalariesSuccessMessage: "New Salaries Added",

    INFOANDFORM: {
      CollaboratorName: "Collaborator Name",
      ValmetId: "Personal Number",
      EmployeeCode: "Employee Code",
      QuarterSalary: "Quarter Salary",
      MonthlySalary: "Monthly Salary",
      MaxBonusCeiling: "Maximum Bonus Amount",
      ConfidentialInformation: "Confidential information"
    },
  },

  ATTACHMENTS: {
    EvaluationAttachments: "Support Manuals",

    INFOANDFORM: {
      FileName: "File Name",
      AddedAttachmentBy: "Added by",
      NewAttachmentAdded: "New attachment added!",
      DeleteAttachmentSuccess: "Attachment removed",
    },

    POPUPS: {
      DeleteAttachment: "Remove",
      DeleteAttachmentConfirmText: "Are you sure you pretend to remove the attachment? Data will be lost permanently!",
      DeleteAttachmentSuccess: "Attachment removed.",
    },

    ACTIONS: {
      AddAttachments: "Add Support Manuals",
    },
  },

  EVALUATIONS: {
    Evaluation: "Evaluation",
    Evaluations: "Evaluations",
    MyEvaluations: "My evaluations",
    BonusCutReasons: "Bonus Cut Reasons",
    EvaluationValidated: "Evaluation Validated!",
    EvaluationStatusUpdatedTo: "Evaluation Status updated to {0}",
    MultipleEvaluationsStatusUpdatedTo: "Possible changed evaluations to {0}",
    EvaluationAttachments: "Support manuals",
    ViewAllAttachments: "View All",
    ManagerEvaluations: "Team Manager {0} evaluations",
    LoadingEvaluations: "Loading evaluations ...",
    NoEvaluationsFound: "No evaluations were found",
    ProblematicAbsencesInfo: "This collaborator has absences marked as problematic",
    SearchForPeriodName: "Search for period name",
    EvaluationsDelegatedTo: "Delegated to {0}",
    EvaluationIndicators: "Performance indicators (individual evaluation)",

    PERFORMANCE: {
      Performance: "Performance",
      Exceptional: "Exceptional",
      VeryGood: "Very Good",
      Good: "Good",
      Reasonable: "Reasonable",
      ToImprove: "Needs to improve",
      ExceptionalDescription: "Exceptional, exceeded all expectations/objectives in many areas",
      VeryGoodDescription: "Very good, met all expectations/objectives or partially exceeded",
      GoodDescription: "Good, achieved most of the expectations/objectives",
      ReasonableDescription: "Reasonable, met some of the expectations/objectives.",
      ToImproveDescription: "Needs improvement, did not meet the expectations/objectives"


    },

    INFOANDFORM: {
      SalaryUndefined: "Salary undefined",
      MidtermScoreTooltip: "Total of company evaluation + Total individual evaluation",
      WeightedAverage: "Weighted average of the individual evaluation",
      RejectedReason: "Rejected Reason",
      Cut: "Cut",
      EmployeeFactorsScore: "Individual Evaluation",
      EmployeeFactorsScoreHeaderTooltip: "Individual Evaluation (based on weighted average)",
      MidtermScore: "Intermediate Evaluation",
      PresentAttendanceRate: "Present Presence Rate",
      PresentMidtermEvaluation: "Present Intermediate Score",
      CancelBonusCut: "Cancel Bonus Cut",
      EmployeeName: "Name",
      Total: "Total",
      Bonus: "Bonus",
      CutBonus: "Cut Bonus",
      BonusCut: "Bonus Cut",
      Revert: "Revert",
      Reject: "Reject",
      Register: "Register",
      Edit: "Edit",
      History: "History",
      Employee: "Employee",
      Reason: "Justification",
      Feedback: "Feedback",
      Comment: "Observation",
      BonusCutApplied: "Bonus Cut applied",
      LastComment: "Last Observation",
      LastMessageToCalibrator: "Last message to Calibrator",
      RejectReasonPlaceholder: "Type a reason for the rejection",
      RegisterFeedbackPlaceholder: "Type the feedback",
      BonusCutReasonPlaceholder: "Type a reason to cut the bonus",
      TotalEmployeeFactors: "Total Employee Factors",
      TotalAbsentismFactors: "Absenteeism",
      TotalCompanyFactor: "Total Company Factors",
      TotalFinalScore: "Final evaluation",
      TotalFinalScoreHeaderTooltip: "Intermediate evaluation * Attendance Rate * (100 - Cut Percentage)",
      TotalFinalScoreWithCutPercentage: "Final evaluation with Cut",
      Observations: "Observations",
      NeedsFeedback: "Must register feedback to collaborator",
      QuarterlyMaximumValue: "Quarterly maximum value",
      AbseentismHeaderTooltip: "Intermediate evaluation * Attendance Rate",
      Presence: "Presence",
      FeedbackToCollaborator: "Feedback to Collaborator",
    },

    STATUS: {
      Rejected: "Rejected",
      CutBonus: "Cut Bonus",
      RejectEvaluation: "Reject Evaluation",
      ConfirmEvaluation: "Confirm Evaluation",
      CalibrateEvaluation: "Calibrate Evaluation",
      RevertCutBonus: "Revert Cut Bonus",
      AddFeedbackToEvaluation: "Add Feedback To Evaluation",
      UpdateEvaluationStatus: "Update Evaluation Status",
      UpdateEvaluationFactor: "Update Evaluation Factor",
      RevertEvaluationStatus: "Revert Evaluation Status",
    },
    MESSAGES: {
      ScoreSavedSuccess: "Evaluation Score Saved",
      MultipleScoresSavedSuccess: "Evaluations score updated"
    },

    POPUPS: {

      NoComments: "No observations were added",
      NoFeedbackGiven: "No Feedback was given to the Collaborator",
      FeedbackGivenToCollaborator: "Feedback given to the Collaborator",
      FeedbackVisibleToCollaborator: "Feedback will be visible to the Collaborator",
      RejectEvaluation: "Reject Evaluation",
      CutBonus: "Cut Bonus",
      RevertText: "Are you sure you want to revert the evaluation status?",
      History: "History",
      RejectEvaluationSuccessMessage: "Evaluation rejected",
      RegisterFeedback: "Feedback to Collaborator",
      RegisterFeedbackSuccess: "Feedback registered!",
      CutEvaluationBonusSuccessMessage: "Bonus cut successfully",
      CancelCutBonusSuccessMessage: "Bonus cut canceled successfully",
      AddEvaluationCommentSuccessMessage: "Observation added!",
      CancelCutBonusConfirmText: "Are you sure you want to cancel this cut bonus?",
      DeleteEvaluationSuccess: "Evaluation deleted.",
      DeleteEvaluationConfirmMessage: "This action permanently deletes the evaluation of this collaborator and is irreversible. Are you sure you want to proceed with the deletion?",
      RemoveEvaluationTitleCAPSLOCK: "Remove Evaluation",
      FeedbackGivenInPerson: "Feedback given to the collaborator in person",
      FeedbackWasGiven: "Feedback was given to the collaborator in person",

    },

    HISTORY: {
      NoHistoryLines: "There are no history records yet for this evaluation.",
      HistoryLineForCuttingBonusWithoutJustification: "Bonus was cut with the reason '{0}'.",
      HistoryLineForCuttingBonusWithJustification: "Bonus was cut with the reason '{0}' and the justification '{1}'.",
      HistoryLineForRejectingEvaluationWithoutJustification: "Evaluation was rejected.",
      HistoryLineForRejectingEvaluationWithJustification: "Evaluation was rejected with the justification '{0}'.",
      HistoryLineForConfirmingEvaluation: "Evaluation was confirmed.",
      HistoryLineForCalibratingEvaluation: "Evaluation was calibrated.",
      HistoryLineForRevertingCutBonus: "Bonus Cut was canceled.",
      HistoryLineForAddingFeedbackToEvaluation: "Feedback '{0}' has been registered.",
      HistoryLineForUpdatingEvaluationStatus: "Evaluation' status was updated.",
      HistoryLineForUpdatingEvaluationFactor: "The percentage of the '{0}' factor has been updated from the value '{1}' to the value '{2}'.",
      HistoryLineForRevertingEvaluationStatus: "Evaluation was reverted.",
      HistoryLineForMarkingFeedbackAsGivenInPerson: "Evaluation's Feedback marked as 'given in person'.",
      HistoryLineForMarkingFeedbackAsNotGivenInPerson: "Evaluation's Feedback marked as 'not given in person'."
    },

    COMMENTS: {
      Comments: "Observations",
      Comment: "Observation",
      InsertComment: "Type the observation",
      MessagesToCalibrator: "Messages to Calibrator",
      MessagesVisibleToCalibrator: "Messages visible to the Calibrator",
      Message: "Message",
      MessagePlaceholder: "Type the message",
    }
  },

  CUTREASONS: {
    CutReason: "Bonus Cut Reason",
    CutReasons: "Bonus Cut Reasons",

    INFOANDFORM: {
      CreateCutReason: "Create Bonus Cut Reason",
      EditCutReason: "Edit Bonus Cut Reason",
      CutReasonNamePlaceholder: "Type Cut Reason Name",
      CutPercentagePlaceholder: "0 - 100",
      Name: "Name",
      NamePT: "Name (pt-PT)",
      NameEN: "Name (en-EN)",
      CutPercentage: "Cut Percentage %",
      CutPercentageAbrev: "Cut (%)",
      CompanyFactors: "Company Factors",
      CompanyFactorsToCut: "Company Factors to Cut",
      EmployeeFactors: "Employee Factors",
      EmployeeFactorsToCut: "Employee Factors to Cut",
      CutFactors: "Cut Factors",
      Description: "Description",
      SelectCutReasonPlaceholder: "Select a Cut Reason"
    },

    POPUP: {
      ActivateCutReason: "Activate",
      ActivateCutReasonConfirmText: "Are you sure you want to activate this cut reason?",
      ActivateCutReasonSuccess: "Cut reason activated.",
      CreateCutReasonSuccess: "Cut reason created!",
      Deactivate: "Deactivate",
      DeactivateCutReasonConfirmText: "Are you sure you want to disable this cut reason?",
      DeactivateCutReasonSuccess: "Cut reason deactivated!",
      EditCutReasonSuccess: "Cut reason edited!",
    },
  },

  GLOBALADMIN: {
    GlobalAdmin: "Global Administration",
    LOCATIONS: {
      Locations: "Locations",
      CreateLocation: "Create Location",
      AccessGroups: "Access Groups",

      INFO: {
        Name: "Name",
        NumGroups: "No. Groups",
        Type: "Type",
      },

      POPUP: {
        Activate: "Activate",
        ActivateConfirmText: "Are you sure you want to activate the location?",
        ActivateLocationSuccess: "Location activated",
        ActivateLocationError: "Error activating location!",
        CreateLocationSuccess: "Location created!",
        EditLocation: "Edit Location",
        EditLocationError: "Error editing location!",
        EditLocationSuccess: "Location edited!",
        DeactivateLocationError: "Error deactivating location!",
        Deactivate: "Disable",
        DeactivateConfirmText: "Are you sure you want to disable the location?",
        DeactivateLocationSuccess: "Location deactivated!"
      },

      ACCESSGROUPS: {
        Type: "Type",
        Name: "Name",
      },
    },

    FACILITIES: {
      CreateFacility: "Create Facility",
      Facilities: "Facilities",
      INFOANDFORM: {
        Name: "Name",
      },
      POPUP: {
        ActivateFacilityConfirmText: "Are you sure you want to activate the facility?",
        ActivateFacilityError: "Error activating facility!",
        ActivateFacilitySuccess: "Facility activated!",
        CreateFacilitySuccess: "Facility created!",
        DeactivateFacilityConfirmText: "Are you sure you want to disable the facility?",
        DeactivateFacilityError: "Error deactivating facility!",
        DeactivateFacilitySuccess: "Facility deactivated!",
        Deactivate: "Disable",
        EditFacilityError: "Error while updating facility!",
        EditFacility: "Edit Facility",
        EditFacilitySuccess: "Facility edited!",
        LoadingFacilityError: "Error while loading facility information."
      }
    },

    ACCESSGROUPS:
    {
      Type: "Type",
      ID: "ID",
      Name: "Name",
      EditedGroupSuccess: "Access groups edited!",
      AccessGroups: "Access Groups",
      SubmitError: "Please, verify errors in the form!",
      TYPES: {
        LocalAdmin: "Local Administrator",
        RH: "Human Resources",
        TeamManager: "Team Manager",
        Collaborator: "Collaborator"
      }
    },

    EMPLOYEES:
    {
      Employees: "Employees List",
      EmployeesAddedSuccess: "Employees and Manager imported.",

      INFOANDFORM: {
        Name: "Name",
        Email: "E-mail",
        JobTitle: "Job Title",
        Department: "Department",
      },
    },

    ABSENCECODES: {
      AbsenceCodes: "Absenteeism Codes",
      AddCode: "Add Code",

      INFOANDFORM: {
        Code: "SAP Code",
        CodePlaceholder: "Ex: 5100",
        Description: "Description",
        DescriptionPlaceholder: "Ex: Medical Leave",
        Problematic: "Problematic",
        ProblematicExplanation: "When SAP's code does not mention work accidents due to worker negligence or not, etc",
        AbsenceCodeStatus: "Absenteeism code indicator"
      },

      POPUPS: {
        Change: "Change",
        ChangeAbsenceCodeProblematicConfirmText: "Are you sure you want to change the absenteeism code indicator to 'Problematic'?",
        ChangeAbsenceCodeIndicatorSuccess: "Absenteeism code indicator changed!",
        ChangeAbsenceCodeNotProblematicConfirmText: "Are you sure you want to change the absenteeism code indicator to 'Not Problematic'?",
        CreateAbsenceCodeSuccess: "Absenteeism code created!",
        DeleteAbsenceCodeSuccess: "Absenteeism code deleted!",
      }
    }
  },

  BONUS: {
    Quarter: "Quarter",
    StartDate: "Start Date",
    EndDate: "End Date",
    Status: "Status",
    Quarter1: "First",
    Quarter2: "Second",
    Quarter3: "Third",
    Quarter4: "Fourth",
    January: "January",
    April: "April",
    August: "August",
    December: "December",

    ROLES: {
      Admin: "Administrator",
      HR: "Human Resources",
      Colaborator: "Collaborator",
      Manager: "Manager"
    },

    COMMON: {
      SearchPlaceholder: "Search",
      Type: "Type",
      Name: "Name",
    },
  }
}
